@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  color: #B4B4B4 !important;
  overflow-x: hidden;
  max-width: 100%;
}

code {
  font-family: Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2.6rem;
  line-height: 3rem;
  font-weight: 600;
  color: #E8E8E8;
  padding-bottom: 2rem;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h2 {
  font-size: 1.8rem;
  line-height: 2.25rem;
  font-weight: 700;
  color: #E8E8E8;
}

h3 {
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: 600;
  color: #E8E8E8;
}

p {
  color: #B4B4B4;
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 300;
}

.para {
  margin-bottom: 2rem;
}

a {
  color: #E8E8E8;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-decoration: none !important;
}

a:hover {
  /* color: #F89248; */
  text-decoration: none;
}

button {
  background-color: #3630eae0;
  font-weight: 900;
  text-transform: uppercase;
  color: #E8E8E8;
  font-size: 1.0rem;
  letter-spacing: 0.03rem;
  display: inline-block;
  padding: 8px 38px 8px 38px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 99999px;
}

button:hover {
  background-color: #3630ea;
  text-decoration: none;
}

button:active {
  background-color: #2C217E;
  text-decoration: none;
}

.btn-secondary {
  font-weight: 900;
  text-transform: uppercase;
  background-color: none;
  border: 2px #3730EA solid;
  color:#B4B4B4;
  font-size: 1.0rem;
  letter-spacing: 0.03rem;
  display: inline-block;
  padding: 8px 38px 8px 38px;
  /* margin-left: auto;
  margin-right: auto; */
  border-radius: 99999px;
}

.btn-secondary:hover {
  color:#E8E8E8;
  background-color: #3730EA;
}

.btn {
  background-color: #3630eae0;
  font-weight: 900;
  text-transform: uppercase;
  color: #E8E8E8;
  font-size: 1.0rem;
  letter-spacing: 0.03rem;
  display: inline-block;
  padding: 8px 38px 8px 38px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 99999px;
}

.btn:hover {
  background-color: #3630ea;
  text-decoration: none;
}

.btn:active {
  background-color: #2C217E;
  text-decoration: none;
}



/* accent: "#3730EA",
offWhite: "#E8E8E8",
secondaryWhite: "#B4B4B4",
almostBlack: "#0F0F0F" */